import * as React from 'react'

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react'
import {
    Field,
    Formik,
    Form
} from 'formik'
import Debug from '../../utils/Debug'
import { UserContext } from '../userContext'
import Axios from 'axios'
import config from '../../../config'
import * as yup from 'yup'

export const PartnerArticleTunnelForm = ({ handleClose }) => {

    const userContext = React.useContext(UserContext);
    const user = userContext.user;

    return (
        <Box
            key={user}
        >
            <Formik
                initialValues={{ email: user ? user.email : '' }}
                validationSchema={
                    yup.object().shape({
                        name: yup.string().required(),
                        url: yup.string().required(),
                        email: yup.string().email().required()
                    })
                }
                onSubmit={(values) => {
                    Axios.post(
                        `${config.apiUrl}/user/partner-article-proposition`,
                        values
                    )
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    submitCount
                }) => (
                    !submitCount ?
                        <Form>
                            <Stack
                                spacing={4}
                            >
                                <SimpleGrid
                                    columns={{ base: 1, lg: 3 }}
                                    gap={2}
                                >
                                    <Field name='name'>
                                        {({ field, meta }) => (
                                            <FormControl
                                                isInvalid={meta.touched && meta.error}
                                            >
                                                <FormLabel>
                                                    Nom du club ou de la classe
                                                </FormLabel>
                                                <Input {...field} placeholder='La godille de Pont Aven' />
                                            </FormControl>
                                        )}

                                    </Field>

                                    <Field name='url'>
                                        {({ field, meta }) => (
                                            <FormControl>
                                                <FormLabel>
                                                    Adresse du site
                                                </FormLabel>
                                                <Input {...field} placeholder='https://...' />
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='email'>
                                        {({ field, meta }) => (
                                            <FormControl
                                                isInvalid={meta.touched && meta.error}

                                            >
                                                <FormLabel>
                                                    Votre email
                                                </FormLabel>
                                                <Input {...field} placeholder='' />
                                            </FormControl>
                                        )}
                                    </Field>
                                </SimpleGrid>

                                <HStack>
                                    <Button
                                        type='submit'
                                        size='sm'
                                        isDisabled={
                                            Object.keys(touched).length === 0
                                            || Object.keys(errors).length > 0
                                        }
                                    >
                                        Envoyer
                                    </Button>
                                    <Button
                                        type='submit'
                                        variant={'link'}
                                        onClick={handleClose}
                                        size='sm'
                                    >
                                        Fermer
                                    </Button>
                                </HStack>

                            </Stack>

                            {/* <Debug data={values} visible={true} /> */}

                        </Form>
                        :
                        <Text fontSize={'sm'}>
                            Youpi, c'est bon, j'ai reçu l'alerte, je vous recontacte rapidement ! 👍
                        </Text>

                )}

            </Formik>

        </Box>
    )
}